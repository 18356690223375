import { effect, Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { SafeStorageService } from '@service/safe-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HomeHeaderModel {
  readonly top = 44.8;
  readonly search = 47.5;
  readonly menu = 36;
  readonly announcementId = signal('');
  readonly announcementContent = signal('');
  readonly showAnnouncement = signal(true);
  readonly isSalesEvent = signal(false);
  readonly showOutfitDot = signal(false);
  readonly height = signal(this.search + this.menu);

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    private localService: SafeStorageService
  ) {
    effect(() => {
      if (isPlatformBrowser(platformId)) {
        this.showAnnouncement.set(
          !!this.announcementId() && this.announcementId() !== localService.getTopAnnouncement()
        );
        this.height.set(this.showAnnouncement() ? this.top + this.search + this.menu : this.search + this.menu);
      }
    });
  }

  setAnnouncement(id: string, content: string): void {
    this.announcementId.set(id);
    this.announcementContent.set(content);
  }

  dismissAnnouncement(): void {
    this.localService.setTopAnnouncement(this.announcementId());
    this.showAnnouncement.set(false);
  }
}
